var Nav = (function($) {
	return {
		clickDelay: 500,
		clickDelayTimer: null,
		paused: false,
		init: function(elem) {
			var $nav = $('nav');
			var $logo = $('.logo');
			if (!isMobileTablet) {
				$(elem).hover(function () {
					if(Nav.clickDelayTimer === null) {
						var $burger = $(this);
						if (!$burger.hasClass('active')) {
							Nav.paused = true;
						} else {
							Nav.paused = false;
						}

						$burger.addClass('active');
						$nav.addClass('active');
						$logo.addClass('hide');

					}
				}, function() {


				});


				$(elem).on('click', function () {

					if(Nav.clickDelayTimer === null && !Nav.paused) {
						var $burger = $(this);
						if($burger.hasClass('active')) {
							$burger.addClass('closing');
							$logo.removeClass('hide');
							$burger.removeClass('active');
							$nav.removeClass('active');
						}

						Nav.clickDelayTimer = setTimeout(function () {
								$burger.removeClass('closing');

								clearTimeout(Nav.clickDelayTimer);
								Nav.clickDelayTimer = null;
							}, Nav.clickDelay);
					}
				});
			} else {
				$(elem).on('click', function () {
					if(Nav.clickDelayTimer === null) {

						var $burger = $(this);

						$burger.toggleClass('active');
						$nav.toggleClass('active');

						if(!$burger.hasClass('active')) {
							$burger.addClass('closing');
						}

						Nav.clickDelayTimer = setTimeout(function () {
							$burger.removeClass('closing');
							clearTimeout(Nav.clickDelayTimer);
							Nav.clickDelayTimer = null;
						}, Nav.clickDelay);
					}
				});
			}



		}
	};

})(jQuery);
