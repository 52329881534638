var People = (function($) {
  return {
    hash: null,
    init: function() {

      if (window.location.hash) {
        People.hash = window.location.hash.substring(1);
        var b = $('html, body'),
          t = $('#'+People.hash);

        b.animate({
          scrollTop: t.offset().top
        }, 800, "easeOut");
      }
    }
  };

})(jQuery);

Initialize.loadPage('post_type_archive_people', People);
