var Home = (function($) {
	return {
		init: function() {
      Scrollify.init();
      Pattern.init('.pattern', 0.1);
      PropertyCategories.init('.property_collections');
      PropertyCategories.init('.social_feed');

      $('.scrollmore').click(function() {
        $.scrollify.move('#properties');
      });

      if (isMobile) {
         $('header.banner').addClass('banner--bg');
      }

      $('.brands').on('init', function(event, slick, direction){
        Home.setHeights();
        // left
      });

      $('.brands').slick({
        infinite: true,
        autoplay: true,
        dots: false,
        dotsClass: 'carousel-indicators',
        slidesToShow: 6,
        slidesToScroll: 6,
        prevArrow: '<span class="left carousel-control nostate"><span class="icon-prev" aria-hidden="true"><svg role="img" xml:space="preserve"><title>Previous</title><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/patrickproperties/dist/images/sprite.svg#arrow"></use></svg></span><span class="sr-only">Previous</span></span>',
        nextArrow: '<span class="right carousel-control nostate"><span class="icon-next" aria-hidden="true"><svg role="img" xml:space="preserve"><title>Next</title><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/patrickproperties/dist/images/sprite.svg#arrow"></use></svg></span><span class="sr-only">Next</span></span>',
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            dots: false,
            arrows:true,
            slidesToScroll: 3,
          }
        }, {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows:false,
            dots: true
          }
        }]

      });

    },
    setHeights: function() {
      $('.brands__img .valign').matchHeight({
            byRow: true,
            property: 'height',
            target: null,
            remove: false
        });
    }
  };

})(jQuery);

Initialize.loadPage('home', Home);
