var ResidentialArchive = (function($) {
  return {
    page: 0,
    pages: 0,
    container: null,
    width: 50,
    seen: 2,
    deltaW: 0,
    scrollMax: 0,
    pageScroll: 0,
    winWidth: 0,
    animating: false,
    wrapperWidth: 0,
    myScroll: null,
    init: function() {
      ResidentialArchive.pages = Math.floor($('.property_collections__page').length)-ResidentialArchive.seen;
      ResidentialArchive.container = $('.property_pagination');

      /*$.jInvertScroll(['.property_slide'],{
        //width: $('.property_collections__page').length*$('.property_collections__page').width(),
        onScroll: function(percent) {
          percent = parseInt(percent*100);
          var trigger = (100/ResidentialArchive.pages);
          if ((percent)%trigger === 0) {
            ResidentialArchive.page = percent/trigger;
          }
        }
      });*/
      if (!isMobile) {
        ResidentialArchive.wrapperWidth = $('.property_collections__page').size();
        $('.property_collections').css('width',((ResidentialArchive.wrapperWidth > 2) ? 50*ResidentialArchive.wrapperWidth : 100) + 'vw');
        ResidentialArchive.myScroll = new IScroll('.property_slide', { scrollX: true, scrollY: false, mouseWheel: true, probeType: 3, tap: true, bounce: false });
        ResidentialArchive.scrollMax = ResidentialArchive.myScroll.maxScrollX;
        ResidentialArchive.pageScroll = ResidentialArchive.scrollMax/ResidentialArchive.pages;
        ResidentialArchive.myScroll.on('scrollEnd', function() {
          ResidentialArchive.animating = false;
        });
        $(window).bind('resize orientationchange', function() {
          ResidentialArchive.resize();
        });

        document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);
      }
      ResidentialArchive.pagination();
      ResidentialArchive.goToProperty();
      ResidentialArchive.hidePagination();
      PropertyCategories.init('.property_collections');

      $('.property_collections').mousewheel(ResidentialArchive.getOffset);

    },
    getOffset: function(e) {
      percent = Math.floor(Math.abs($('.property_collections').offset().left)/Math.abs(ResidentialArchive.scrollMax)*100);
      trigger = Math.floor(100/ResidentialArchive.pages);
      if ((percent)%trigger === 0) {
        ResidentialArchive.page = percent/trigger;
        ResidentialArchive.hidePagination(ResidentialArchive.page);
      }
    },
    pagination: function() {

      (ResidentialArchive.pages <= 0) ? $('.arrow.next').addClass('hide') : '';

      ResidentialArchive.container.children('.next').on('click', function() {
        if (!ResidentialArchive.animating) {
          ResidentialArchive.animating = true;
          ResidentialArchive.page = ResidentialArchive.page+1;
          ResidentialArchive.hidePagination(ResidentialArchive.page);
          ResidentialArchive.myScroll.scrollTo(ResidentialArchive.pageScroll*(ResidentialArchive.page), 0, 700, IScroll.utils.ease.circular);
        }

      });
      ResidentialArchive.container.children('.prev').on('click', function() {
        if (!ResidentialArchive.animating) {
          ResidentialArchive.animating = true;
          ResidentialArchive.page = ResidentialArchive.page-1;
          ResidentialArchive.hidePagination(ResidentialArchive.page);
          ResidentialArchive.myScroll.scrollTo(ResidentialArchive.pageScroll*(ResidentialArchive.page), 0, 700, IScroll.utils.ease.circular);
        }
      });
    },
    hidePagination: function(val) {

      if (val == ResidentialArchive.pages || ResidentialArchive.pages <= 0) {
        $('.arrow.next').addClass('hide');
      } else {
        $('.arrow.next').removeClass('hide');
      }
      if (val > 0) {
        $('.arrow.prev').removeClass('hide');
      } else {
        $('.arrow.prev').addClass('hide');
      }
    },
    resize: function() {
      ResidentialArchive.myScroll.refresh();
      ResidentialArchive.scrollMax = ResidentialArchive.myScroll.maxScrollX;
      ResidentialArchive.pageScroll = ResidentialArchive.scrollMax/ResidentialArchive.pages;
    },
    goToProperty: function() {
      var hash;
      if (!isMobile) {
        if (window.location.hash) {
          hash = window.location.hash.substring(1);
          var t = $('#'+hash);
          ResidentialArchive.myScroll.scrollTo(-t.offset().left, 0, 700, IScroll.utils.ease.circular);
        }
      }
    }

  };

})(jQuery);

Initialize.loadPage('post_type_archive_residential', ResidentialArchive);
