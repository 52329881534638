var Pattern = (function($) {
	return {
		init: function(elem, opacity) {
			var s, square, diamond, dashes, line, diamondTwo, pattern;

			s = Snap(elem);

			var Animation = {
				animIn: function() {
					var that = this;
					var intTime = Math.floor((Math.random() * 10000) + 700);
					setTimeout(function() {
						that.animate({'stroke-dashoffset':0}, 700,mina.linear, Animation.animOut);
					}, intTime);
				},
				animOut: function() {
					var that = this;
					var intTime = Math.floor((Math.random() * 10000) + 700);
					setTimeout(function() {
						that.animate({'stroke-dashoffset':60}, 700,mina.linear, Animation.animIn);
					}, intTime);
				},
				animDashIn: function() {
					var that = this;
					var intTime = Math.floor((Math.random() * 10000) + 700);
					setTimeout(function() {
						that.animate({'stroke-dasharray': 8.34, 'stroke-dashoffset': 0}, 700,mina.linear, Animation.animDashOut);
					}, intTime);
				},
				animDashOut: function() {
					var that = this;
					var intTime = Math.floor((Math.random() * 10000) + 700);
					setTimeout(function() {
						that.animate({'stroke-dasharray': 60, 'stroke-dashoffset':60}, 700,mina.linear, Animation.animDashIn);
					}, intTime);
				},
				animInLarge: function() {
					var that = this;
					var intTime = Math.floor((Math.random() * 10000) + 700);
					setTimeout(function() {
						that.animate({'stroke-dashoffset':0}, 700,mina.linear, Animation.animOutLarge);
					}, intTime);
				},

				animOutLarge: function() {
					var that = this;
					var intTime = Math.floor((Math.random() * 10000) + 700);
					setTimeout(function() {
						that.animate({'stroke-dashoffset':100}, 700,mina.linear, Animation.animInLarge);
					}, intTime);
				}
			};

			square = s.rect(45.6, 45.6, 8.7, 8.7).attr({
				fill:'none',
				'stroke-opacity': opacity,
				stroke:'#fff',
				strokeWidth:'1.042',
				'stroke-miterlimit':10,
				'stroke-dasharray': 60,
				'stroke-dashoffset': 60
			}).animate({'stroke-dashoffset': 60},700,mina.linear,Animation.animIn).toPattern(0,0,100,100);

			diamond = s.rect(43.7,43.7,12.6,12.6).transform('matrix(0.7071,0.7071,-0.7071,0.7071,50,-20.7107)').attr({
				fill:'none',
				'stroke-opacity': opacity,
				stroke:'#fff',
				strokeWidth:'1.042',
				'stroke-miterlimit':10,
				'stroke-dasharray': 60,
				'stroke-dashoffset': 60
			}).animate({'stroke-dashoffset': 60},700,mina.linear,Animation.animIn).toPattern(0,0,100,100);

			line = s.line(50,28.5,50, 71.5).attr({
				fill:'none',
				stroke:'#fff',
				strokeWidth:'1.042',
				'stroke-opacity': opacity,
				'stroke-miterlimit':10,
				'stroke-dasharray': 60,
				'stroke-dashoffset': 50
			}).animate({'stroke-dashoffset': 60},700,mina.linear,Animation.animIn).toPattern(0,0,100,100);

			dashes = s.line(71.5,50,28.5,50).attr({
				fill:'none',
				stroke:'#fff',
				strokeWidth:'1.042',
				'stroke-opacity': opacity,
				'stroke-miterlimit':10,
				'stroke-dasharray': 60,
				'stroke-dashoffset': 50
			}).animate({'stroke-dashoffset': 60},700,mina.linear,Animation.animDashIn).toPattern(0,0,100,100);

			diamondTwo = s.rect(40.4,40.4,19.1,19.1).transform('matrix(4.505888e-11,1,-1,4.505888e-11,100,-2.254311e-9)').attr({
				fill:'none',
				'stroke-opacity': opacity,
				stroke:'#fff',
				strokeWidth:'1.042',
				'stroke-miterlimit':10,
				'stroke-dasharray': 100,
				'stroke-dashoffset': 100
			}).animate({'stroke-dashoffset': 100},700,mina.linear,Animation.animInLarge).toPattern(0,0,100,100);

			pattern = Array(square,diamond,line,dashes,diamondTwo);

			for (i=0;i < pattern.length; i++) {
				s.rect(0,0,'100%','100%').attr({fill: pattern[i]});
			}



		}
	};

})(jQuery);
