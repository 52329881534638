var Commercial = (function($) {
  return {
    init: function() {
      PropertyCategories.init('.property_collections', true, false);
      $('.property_collections article .body').matchHeight({
          byRow: true,
          property: 'min-height',
          target: null,
          remove: false
      });

      $(window).bind('resize orientationchange', function(event) {
        $.fn.matchHeight._update();
      });
    }
  };

})(jQuery);

Initialize.loadPage('commercial', Commercial);
