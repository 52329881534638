var CategoryPage = (function($) {
  return {
    page: 0,
    pages: 0,
    container: null,
    width: 50,
    seen: 2,
    deltaW: 0,
    scrollMax: 0,
    pageScroll: 0,
    winWidth: 0,
    animating: false,
    wrapperWidth: 0,
    myScroll: null,
    init: function() {
      CategoryPage.pages = Math.floor($('.property_collections__page').length)-CategoryPage.seen;
      CategoryPage.container = $('.property_pagination');

      /*$.jInvertScroll(['.property_slide'],{
        //width: $('.property_collections__page').length*$('.property_collections__page').width(),
        onScroll: function(percent) {
          percent = parseInt(percent*100);
          var trigger = (100/CategoryPage.pages);
          if ((percent)%trigger === 0) {
            CategoryPage.page = percent/trigger;
          }
        }
      });*/
      if (!isMobile) {
        CategoryPage.wrapperWidth = $('.property_collections__page').size();
        $('.property_collections').css('width',((CategoryPage.wrapperWidth > 2) ? 50*CategoryPage.wrapperWidth : 100) + 'vw');
        CategoryPage.myScroll = new IScroll('.property_slide', { scrollX: true, scrollY: false, mouseWheel: true, probeType: 3 });
        CategoryPage.scrollMax = CategoryPage.myScroll.maxScrollX;
        CategoryPage.pageScroll = CategoryPage.scrollMax/CategoryPage.pages;
        CategoryPage.myScroll.on('scrollEnd', function() {
          CategoryPage.animating = false;
        });
        $(window).bind('resize orientationchange', function() {
          CategoryPage.resize();
        });

        document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);
      }
      CategoryPage.pagination();
      CategoryPage.hidePagination();
      PropertyCategories.init('.property_collections');

      $('.property_collections').mousewheel(CategoryPage.getOffset);

    },
    getOffset: function(e) {
      percent = Math.floor(Math.abs($('.property_collections').offset().left)/Math.abs(CategoryPage.scrollMax)*100);
      trigger = Math.floor(100/CategoryPage.pages);
      if ((percent)%trigger === 0) {
        CategoryPage.page = percent/trigger;
        CategoryPage.hidePagination(CategoryPage.page);
      }
    },
    pagination: function() {

      (CategoryPage.pages <= 0) ? $('.arrow.next').addClass('hide') : '';

      CategoryPage.container.children('.next').on('click', function() {
        if (!CategoryPage.animating) {
          CategoryPage.animating = true;
          CategoryPage.page = CategoryPage.page+1;
          CategoryPage.hidePagination(CategoryPage.page);
          CategoryPage.myScroll.scrollTo(CategoryPage.pageScroll*(CategoryPage.page), 0, 700, IScroll.utils.ease.circular);
        }

      });
      CategoryPage.container.children('.prev').on('click', function() {
        if (!CategoryPage.animating) {
          CategoryPage.animating = true;
          CategoryPage.page = CategoryPage.page-1;
          CategoryPage.hidePagination(CategoryPage.page);
          CategoryPage.myScroll.scrollTo(CategoryPage.pageScroll*(CategoryPage.page), 0, 700, IScroll.utils.ease.circular);
        }
      });
    },
    hidePagination: function(val) {

      if (val == CategoryPage.pages || CategoryPage.pages <= 0) {
        $('.arrow.next').addClass('hide');
      } else {
        $('.arrow.next').removeClass('hide');
      }
      if (val > 0) {
        $('.arrow.prev').removeClass('hide');
      } else {
        $('.arrow.prev').addClass('hide');
      }
    },
    resize: function() {
      CategoryPage.myScroll.refresh();
      CategoryPage.scrollMax = CategoryPage.myScroll.maxScrollX;
      CategoryPage.pageScroll = CategoryPage.scrollMax/CategoryPage.pages;
    }

  };

})(jQuery);

Initialize.loadPage('category', CategoryPage);
