var Button = (function($) {
	return {
		timeline: new TimelineMax(),
		init: function() {
			Button.cssChange();

			$('a.btn').on('mouseenter',function() {
				$(this).trigger('cssClassChanged', { hover: true });
			});

			$('a.btn').on('mouseleave',function() {
				$(this).trigger('cssClassChanged', { hover: false });
			});

		},
		cssChange: function() {
			$('.btn').bind('cssClassChanged', function(e, data){
				if (data.hover) {
					if ($(this).has( 'span.br-t' ).length) {
						Button.buttonAnimOver(this);
					}
				} else {
					if ($(this).has( 'span.br-t' ).length) {
						// Clear timeline
						Button.timeline.clear();
						// Reset button state
						Button.timeline.add( TweenLite.to($('.br-l', this), 0.2, {ease: Quad.easeInOut, height:'100%'}) );
						Button.timeline.add( TweenLite.to($('.br-b', this), 0.2, {ease: Quad.easeInOut, width:'100%'}) );
						Button.timeline.add( TweenLite.to($('.br-r', this), 0.2, {ease: Quad.easeInOut, height:'100%'}) );
						Button.timeline.add( TweenLite.to($('.br-t', this), 0.2, {ease: Quad.easeInOut, width:'100%'}));
						// Remove on-hover state class
						$('.br-t', this).removeClass('active');
						$('.br-r', this).removeClass('active');
						$('.br-l', this).removeClass('active');
						$('.br-b', this).removeClass('active');
					}
				}
			});
		},
		buttonSetListener: function(btn) {
			$(btn).hover(function() {
				if ($(this).has( 'span.br-t' ).length) {
					Button.buttonAnimOver(this);
				}
			}, function() {
				if ($(this).has( 'span.br-t' ).length) {
					// Clear timeline
					Button.timeline.clear();
					// Reset button state
					Button.timeline.add( TweenLite.to($('.br-l', this), 0.2, {ease: Quad.easeInOut, height:'100%'}) );
					Button.timeline.add( TweenLite.to($('.br-b', this), 0.2, {ease: Quad.easeInOut, width:'100%'}) );
					Button.timeline.add( TweenLite.to($('.br-r', this), 0.2, {ease: Quad.easeInOut, height:'100%'}) );
					Button.timeline.add( TweenLite.to($('.br-t', this), 0.2, {ease: Quad.easeInOut, width:'100%'}));
					// Remove on-hover state class
					$('.br-t', this).removeClass('active');
					$('.br-r', this).removeClass('active');
					$('.br-l', this).removeClass('active');
					$('.br-b', this).removeClass('active');
				}
			});
		},
		buttonAnimOver: function(current) {
			// Remove Border and add Class on Complete.
			Button.timeline.add( TweenLite.to($('.br-t', current), 0.1, {onComplete: function() { $('.br-t', current).addClass('active');  }, ease: Quad.easeInOut, width:0}) );
			Button.timeline.add( TweenLite.to($('.br-r', current), 0.1, {onComplete: function() { $('.br-r', current).addClass('active');  }, ease: Quad.easeInOut, height:0}) );
			Button.timeline.add( TweenLite.to($('.br-b', current), 0.1, {onComplete: function() { $('.br-b', current).addClass('active');  }, ease: Quad.easeInOut, width:0}) );
			Button.timeline.add( TweenLite.to($('.br-l', current), 0.1, {onComplete: function() { $('.br-l', current).addClass('active');  }, ease: Quad.easeInOut, height:0}) );
			// Redraw border
			Button.timeline.add( TweenLite.to($('.br-l', current), 0.1, {ease: Quad.easeInOut, height:'100%'}) );
			Button.timeline.add( TweenLite.to($('.br-b', current), 0.1, {ease: Quad.easeInOut, width:'100%'}) );
			Button.timeline.add( TweenLite.to($('.br-r', current), 0.1, {ease: Quad.easeInOut, height:'100%'}) );
			Button.timeline.add( TweenLite.to($('.br-t', current), 0.1, {ease: Quad.easeInOut, width:'100%'}) );
		}
	};
})(jQuery);
