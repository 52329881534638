var BlogSingle = (function($) {
  return {
    init: function() {
      Map.init('.map');
      PropertyCategories.init('.property_collections');
      $('.breadcrumbs').css('top', ($('h1:first').offset().top - $('.comsingle__gallery').height()) + 88);
      $('.carousel').carousel();
      if (isMobile) {
        $('#myTab').tabCollapse();
      }
    }
  };

})(jQuery);

Initialize.loadPage('single_post', BlogSingle);
