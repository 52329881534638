var About = (function($) {
  return {
    init: function() {
       Pattern.init('.pattern', 0.4);

       $('.about__business--image .valign').matchHeight({
          byRow: true,
          property: 'height',
          target: null,
          remove: false
      });

       $('article', $('.property_collections')).on('click tap', function() {
        $(this).children('.table').children('.table-cell').children('a.btn').trigger('loadPage', { page: $(this).children('.table').children('.table-cell').children('a.btn').attr('href') });
      });
    }
  };
})(jQuery);

Initialize.loadPage('about', About);
