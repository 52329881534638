var Preloader = (function($) {
	return {
		$preloader: $('.preloader'),
		init: function() {
			var $container = $('#main');

			Preloader.doPreload();
			setTimeout( function(){
				if ( $( ".first" ).length ) {
					$( ".first" ).removeClass('first');
					setTimeout( function(){
						$( "#main" ).addClass('show');
					},500);
					Preloader.$preloader.addClass('preloader--hide');
					Preloader.$preloader.removeClass('preloader--show');
				}
			}, 1560);

			$('a:not(.nostate) , #header a').click(function(event) {
				event.preventDefault();
				var e = $(this).attr("href");
				if ( $( ".first" ).length ) {
					return $container.addClass( 'fadeOut' ), $container.removeClass('show'), $('.preloader__loader').remove(), Preloader.$preloader.addClass('preloader--show'), Preloader.$preloader.removeClass('preloader--hide'), setTimeout(function() { window.location = e; },500);
				} else { return setTimeout(function() { window.location = e; },500); }
			});


			/*$(window).bind("pageshow", function(event) {
					console.log(event.originalEvent.persisted);
			    if (event.originalEvent.persisted) {
			        window.location.reload();
			    }
			});*/

			/*window.onunload=function(){void(0);}*/
			Preloader.loadPage();

		},
		doPreload: function() {
			if (Preloader.$preloader.length) {
				var s = Snap('.preloader svg');
				var g = s.group();
				Snap.load('/wp-content/themes/patrickproperties/dist/images/preloader.svg', function ( loadedFragment ) {
          g.append( loadedFragment );
          g.select('path').animate({'stroke-dashoffset': 175},0,mina.linear,Preloader.animIn)
        });
				setTimeout(function() {
        	s.remove();
        }, 1900);
			}
		},
		loadPage: function() {
				$('.btn').bind('loadPage', function(e, data){

						if ( location.hostname === this.hostname || !this.hostname.length) {
							window.location = data.page;
							return true;
						} else {
							var win = window.open(data.page, '_blank');
  						win.focus();
						}
						return false;
				});
		},
		animIn: function() {
			var that = this;
			var intTime = 0;
			//setTimeout(function() {
				that.animate({'stroke-dashoffset':0}, 1060,mina.linear, Preloader.animOut);
			//}, intTime);
		},
		animOut: function() {
			var that = this;
			var intTime = 0;
			//setTimeout(function() {
				that.animate({'stroke-dashoffset':175}, 1060,mina.linear, Preloader.animIn);
			//}, intTime);
		}
	};

})(jQuery);
