var ResidentialProperty = (function($) {
  return {
    init: function() {
      Map.init('.map');
      PropertyCategories.init('.property_collections');
      $('.breadcrumbs').css('top', ($('h1:first').offset().top - $('.comsingle__gallery').height()) + 88);
      $('.comsingle--img .valign').matchHeight({
          byRow: true,
          property: 'height',
          target: null,
          remove: false
      });
      $('.carousel').carousel();
      if (isMobile) {
        $('#myTab').tabCollapse();
      }
    }
  };

})(jQuery);

Initialize.loadPage('single_residential', ResidentialProperty);
