var Footer = (function($) {
	return {
		container: null,
		init: function() {
			Footer.container = $('footer');
			//$( document ).mousemove(Footer.toggleFooter);
			if (!isMobileTablet) {
				Footer.container.hover(function() {
					Footer.container.addClass('show');
				}, function() {
					Footer.container.removeClass('show');
				});
			}
			if (isMobileTablet) {
				Footer.container.addClass('show');
			}
		},
		toggleFooter: function(event) {
			if (!isMobileTablet) {
				var percent = $(document).innerHeight() - 75;
				if (event.pageY > percent) {
					Footer.container.addClass('show');
				} else {
					Footer.container.removeClass('show');
				}
			}
		}
	};

})(jQuery);
