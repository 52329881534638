var Blog = (function($) {
  return {
    page: 0,
    pages: 0,
    container: null,
    width: 33.333333333,
    seen: 3,
    deltaW: 0,
    scrollMax: 0,
    pageScroll: 0,
    winWidth: 0,
    animating: false,
    wrapperWidth: 0,
    myScroll: null,
    init: function() {
      if ($(window).width() >= 768 && $(window).width() <= 1200) {
        Blog.seen = 2;
      } else {
        Blog.seen = 3;
      }
      Blog.pages = Math.floor($('.property_collections__page').length)-Blog.seen;
      Blog.container = $('.property_pagination');

      /*$.jInvertScroll(['.property_slide'],{
        //width: $('.property_collections__page').length*$('.property_collections__page').width(),
        onScroll: function(percent) {
          percent = parseInt(percent*100);
          var trigger = (100/Blog.pages);
          if ((percent)%trigger === 0) {
            Blog.page = percent/trigger;
          }
        }
      });*/
      if (!isMobile) {
        Blog.wrapperWidth = $('.property_collections__page').size();

        Blog.setWidths();
        $(window).bind('resize orientationchange', function(event) {
          Blog.resize();
          Blog.setWidths();
          if ($(window).width() >= 768 && $(window).width() <= 1200) {
            Blog.seen = 2;
          } else {
            Blog.seen = 3;
          }
        });

        Blog.myScroll = new IScroll('.property_slide', { scrollX: true, scrollY: false, mouseWheel: true, probeType: 3, tap: true, bounce: false });
        Blog.scrollMax = Blog.myScroll.maxScrollX;
        Blog.pageScroll = Blog.scrollMax/Blog.pages;
        Blog.myScroll.on('scrollEnd', function() {
          Blog.animating = false;
        });


        document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);
      }
      Blog.pagination();
      Blog.goToBlog();
      PropertyCategories.init('.property_collections');

      $('.property_collections').mousewheel(Blog.getOffset);


    },
    getOffset: function(e) {
      percent = Math.floor(Math.abs($('.property_collections').offset().left)/Math.abs(Blog.scrollMax)*100);
      trigger = Math.floor(100/Blog.pages);
      if ((percent)%trigger === 0) {
        Blog.page = percent/trigger;
        Blog.hidePagination(Blog.page);
      }
    },
    pagination: function() {

      (Blog.pages <= 0) ? $('.arrow.next').addClass('hide') : '';

      Blog.container.children('.next').on('click', function() {
        if (!Blog.animating) {
          Blog.animating = true;
          Blog.page = Blog.page+1;
          Blog.hidePagination(Blog.page);
          Blog.myScroll.scrollTo(Blog.pageScroll*(Blog.page), 0, 700, IScroll.utils.ease.circular);
        }

      });
      Blog.container.children('.prev').on('click', function() {
        if (!Blog.animating) {
          Blog.animating = true;
          Blog.page = Blog.page-1;
          Blog.hidePagination(Blog.page);
          Blog.myScroll.scrollTo(Blog.pageScroll*(Blog.page), 0, 700, IScroll.utils.ease.circular);
        }
      });
    },
    setWidths: function() {
      if (!isMobile) {
        if ($(window).width() >= 768 && $(window).width() <= 1200) {
          $('.property_collections').css('width',((Blog.wrapperWidth > 2) ? 50*Blog.wrapperWidth : 100) + 'vw');
          $('.property_collections__page').each(function(i) {
            $(this).css('left', i*50+'vw');
          });
        } else {
          $('.property_collections').css('width',((Blog.wrapperWidth > 2) ? 33.333333333*Blog.wrapperWidth : 100) + 'vw');
          $('.property_collections__page').each(function(i) {
            $(this).css('left', i*33.333333333+'vw');
          });
        }
      }

    },
    hidePagination: function(val) {

      if (val == Blog.pages) {
        $('.arrow.next').addClass('hide');
      } else {
        $('.arrow.next').removeClass('hide');
      }
      if (val > 0) {
        $('.arrow.prev').removeClass('hide');
      }  else {
        $('.arrow.prev').addClass('hide');
      }
    },
    resize: function() {
      Blog.myScroll.refresh();
      Blog.scrollMax = Blog.myScroll.maxScrollX;
      Blog.pageScroll = Blog.scrollMax/Blog.pages;
    },
    goToBlog: function() {
      var hash;
      if (!isMobile) {
        if (window.location.hash) {
          hash = window.location.hash.substring(1);
          var t = $('#'+hash);
          Blog.myScroll.scrollTo(-t.offset().left, 0, 700, IScroll.utils.ease.circular);
        }
      }
    }

  };

})(jQuery);

Initialize.loadPage('blog', Blog);
