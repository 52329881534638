var PropertyCategories = (function($) {
	return {
		pause: false,
		buttonHover: true,
		init: function(elem, removeOverlay, buttonHover) {
			elem = $(elem);
			PropertyCategories.buttonHover = (typeof buttonHover === 'undefined') ? PropertyCategories.buttonHover : false;
			if (!removeOverlay) {
						$('article', elem).hover(function () {


						      	$(this).addClass('active');
					        		$(this).children('.table').children('.table-cell').children('a.btn').trigger('cssClassChanged', { hover: true });
					        		$(this).children('.table').children('.table-cell').children('a.btn').addClass('btn--active');
					        		return $('article:not(.active)', elem).addClass('nonactive');


					    }, function () {

					    		$(this).children('.table').children('.table-cell').children('a.btn').trigger('cssClassChanged', { hover: false });
					    		$(this).children('.table').children('.table-cell').children('a.btn').removeClass('btn--active');
					    		//$(this).children('.overlay').removeClass('overlay--hide');
					        	return $('article', elem).removeClass('active nonactive');

					    });
		        	} else {
		        		$('article', elem).each(function() {
		        			PropertyCategories.mouseEvent($(this), elem);
		        		});



		        	}
		   if (!isMobileTablet) {
				$('article', elem).on('click', function(event) {
					if ( !$(event.target).hasClass('btn')) {
						$(this).children('.table').children('.table-cell').children('a.btn').trigger('loadPage', { page: $(this).children('.table').children('.table-cell').children('a.btn').attr('href') });
					}
				});
			} else {
				$('article', elem).on('tap', function(event) {
					console.log('ere');
					if ( !$(event.target).hasClass('btn')) {
						$(this).children('.table').children('.table-cell').children('a.btn').trigger('loadPage', { page: $(this).children('.table').children('.table-cell').children('a.btn').attr('href') });
					}
				});
			}

		},
		mouseEvent: function(elem, others) {
			$(elem).mousemove(function(event) {
				var percent = $(this).children('.table').children('.table-cell').children('header').offset().top;
				var left = $(this).offset().left;
				var width = left + $(this).width();
				var top = $(this).offset().top;
				var height = top + $(this).height();

				var hover = false;

				if (event.pageY > percent) {
					if (!PropertyCategories.pause) {
						$(this).children('.overlay').css('top', ((percent-top)-30));
						PropertyCategories.resetTop(others, $(this).index());
						$(this).addClass('active');
			        		if (PropertyCategories.buttonHover) { $(this).children('.table').children('.table-cell').children('a.btn').trigger('cssClassChanged', { hover: true }); }
			        		$(this).children('.table').children('.table-cell').children('a.btn').addClass('btn--active');
			        		$('article:not(.active)', others).addClass('nonactive');
			        		PropertyCategories.pause = true;
		      }
				}
				if (event.pageY < percent || (event.pageX <= left || event.pageX > width || event.pageY > height)) {

					if (PropertyCategories.pause) {
						$(this).children('.overlay').css('top', 0);
						if (PropertyCategories.buttonHover) { $(this).children('.table').children('.table-cell').children('a.btn').trigger('cssClassChanged', { hover: false }); }
				    		$(this).children('.table').children('.table-cell').children('a.btn').removeClass('btn--active');
				        	$('article', others).removeClass('active nonactive');
				        	PropertyCategories.pause = false;
				      }
				}

				/*if (event.pageX > width) {
					if (PropertyCategories.pause) {
						$(this).children('.overlay').css('top', 0)
						$(this).children('a.btn').trigger('cssClassChanged', { hover: false });
				    		$(this).children('a.btn').removeClass('btn--active');
				        	$('article', others).removeClass('active nonactive');
				        	PropertyCategories.pause = false;
				      }
				}*/

			});
		},
		resetTop: function(elem, i) {

			$('article', elem).not(':eq('+i+')').each(function() {
				$(this).children('.table').children('.table-cell').children('a.btn').removeClass('btn--active');
				$(this).children('.overlay').css('top', 0);
			});
		}
	};

})(jQuery);
