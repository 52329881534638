var Contact = (function($) {
  return {
    init: function() {
       Map.init('.page-template__map', 17);
       Pattern.init('.pattern', 0.4);
    }
  };

})(jQuery);

Initialize.loadPage('contact_us', Contact);
