var Scrollify = (function($) {
	return {
		init: function() {
			if (!isMobileTablet) {
				$.scrollify({
					section: '.scrollme',
					setHeights: false,
					scrollbars: true,
					scrollSpeed: 700,
					updateHash: false,
					easing: 'easeOutExpo',
					after: function(i, evt) {
						$('.scrollme').eq(i).addClass('show');
					},
					before: function(i, evt) {

						$.when(
							$('.scrollme').each(function() {
								$(this).removeClass('show');
							})
						);

						$.when(

							$('.pagination a').each(function() {
								$(this).removeClass('pagination--active');
							})

							).then(function() {
								$('.pagination a').eq(i).addClass('pagination--active');
								/*$('.pagination').removeClass('pagination--black');*/
								$('.pagination').removeClass('pagination--hidden');
								/*if (i == 1) {
									$('.pagination').addClass('pagination--black');
								}*/
								if (i == 2) {
									//$('.pagination').addClass('pagination--hidden');
								}
							});

							return;

						},
						afterRender: function() {
							var ind = $.scrollify.current().index();
							/*if (ind == 1) {
								$('.pagination').addClass('pagination--black');
							}*/
							if (ind == 2) {
								//$('.pagination').addClass('pagination--hidden');
							}
							$('.pagination a').on('click', function(evt) {
								evt.preventDefault();
								$.scrollify.move($('.pagination a').index($(this)));
							});
						}
					});
			}
		}
	};

})(jQuery);
