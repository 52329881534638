var CommercialTypes = (function($) {
  return {
    page: 0,
    pages: 0,
    container: null,
    width: 50,
    seen: 2,
    deltaW: 0,
    scrollMax: 0,
    pageScroll: 0,
    winWidth: 0,
    animating: false,
    wrapperWidth: 0,
    myScroll: null,
    init: function() {
      CommercialTypes.pages = Math.floor($('.property_collections__page').length)-CommercialTypes.seen;
      CommercialTypes.container = $('.property_pagination');

      /*$.jInvertScroll(['.property_slide'],{
        //width: $('.property_collections__page').length*$('.property_collections__page').width(),
        onScroll: function(percent) {
          percent = parseInt(percent*100);
          var trigger = (100/CommercialTypes.pages);
          if ((percent)%trigger === 0) {
            CommercialTypes.page = percent/trigger;
          }
        }
      });*/

      if (!isMobile) {
        CommercialTypes.wrapperWidth = $('.property_collections__page').size();
        $('.property_collections').css('width',((CommercialTypes.wrapperWidth > 2) ? 50*CommercialTypes.wrapperWidth : 100) + 'vw');
        CommercialTypes.myScroll = new IScroll('.property_slide', { scrollX: true, scrollY: false, mouseWheel: true, probeType: 3, tap: true, bounce: false });
        CommercialTypes.scrollMax = CommercialTypes.myScroll.maxScrollX;
        CommercialTypes.pageScroll = CommercialTypes.scrollMax/CommercialTypes.pages;
        CommercialTypes.myScroll.on('scrollEnd', function() {
          CommercialTypes.animating = false;
        });
        $(window).bind('resize orientationchange', function() {
          CommercialTypes.resize();
        });

        document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);
      }
      CommercialTypes.pagination();
      CommercialTypes.goToProperty();
      CommercialTypes.hidePagination();
      PropertyCategories.init('.property_collections');

      $('.property_collections').mousewheel(CommercialTypes.getOffset);

    },
    getOffset: function(e) {
      percent = Math.floor(Math.abs($('.property_collections').offset().left)/Math.abs(CommercialTypes.scrollMax)*100);
      trigger = Math.floor(100/CommercialTypes.pages);
      if ((percent)%trigger === 0) {
        CommercialTypes.page = percent/trigger;
        CommercialTypes.hidePagination(CommercialTypes.page);
      }
    },
    pagination: function() {

      (CommercialTypes.pages <= 0) ? $('.arrow.next').addClass('hide') : '';

      CommercialTypes.container.children('.next').on('click', function() {
        if (!CommercialTypes.animating) {
          CommercialTypes.animating = true;
          CommercialTypes.page = CommercialTypes.page+1;
          CommercialTypes.hidePagination(CommercialTypes.page);
          CommercialTypes.myScroll.scrollTo(CommercialTypes.pageScroll*(CommercialTypes.page), 0, 700, IScroll.utils.ease.circular);
        }

      });
      CommercialTypes.container.children('.prev').on('click', function() {
        if (!CommercialTypes.animating) {
          CommercialTypes.animating = true;
          CommercialTypes.page = CommercialTypes.page-1;
          CommercialTypes.hidePagination(CommercialTypes.page);
          CommercialTypes.myScroll.scrollTo(CommercialTypes.pageScroll*(CommercialTypes.page), 0, 700, IScroll.utils.ease.circular);
        }
      });
    },
    hidePagination: function(val) {

      if (val == CommercialTypes.pages || CommercialTypes.pages <= 0) {
        $('.arrow.next').addClass('hide');
      } else {
        $('.arrow.next').removeClass('hide');
      }
      if (val > 0) {
        $('.arrow.prev').removeClass('hide');
      } else {
        $('.arrow.prev').addClass('hide');
      }
    },
    resize: function() {
      CommercialTypes.myScroll.refresh();
      CommercialTypes.scrollMax = CommercialTypes.myScroll.maxScrollX;
      CommercialTypes.pageScroll = CommercialTypes.scrollMax/CommercialTypes.pages;
    },
    goToProperty: function() {
      var hash;
      if (!isMobile) {
        if (window.location.hash) {
          hash = window.location.hash.substring(1);
          var t = $('#'+hash);
          CommercialTypes.myScroll.scrollTo(-t.offset().left, 0, 700, IScroll.utils.ease.circular);
        }
      }
    }

  };

})(jQuery);

Initialize.loadPage('tax_types', CommercialTypes);
